import React from "react";

import { FormattedMessage } from "gatsby-plugin-intl";

// This component is a wrapper for all intl text that need to be formatted according to our format rules (<b>, <i>, <br/> ...)
const FlopFormattedMessageWrapper = ({ id }) => {
  return (
    <FormattedMessage
      values={{
        b: (...text) => <b>{text}</b>,
        i: (...text) => <i>{text}</i>,
        ul: (...text) => <ul>{text}</ul>,
        li: (...text) => <li>{text}</li>,
        br: <br />,
      }}
      id={id}
    />
  );
};
export default FlopFormattedMessageWrapper;

import React, { useEffect, useContext } from "react";

import CookieBanner from "../Layout/CookieBanner";
import GlobalContext from "../../context/GlobalContext";
import Rodal from 'rodal';
import wptGlobalPng from "../../assets/image/flop/blog/WPT_Global_details.png";
import {Button} from "../Core";

const PageWrapper = ({ children, headerDark = false, footerDark = false }) => {
  const gContext = useContext(GlobalContext);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const endOfTheEventDate = new Date('2022-06-08T10:00:00Z');
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"
  const date = new Date();
  if (endOfTheEventDate.getTime() < date.getTime()) {
    if (typeof window !== 'undefined') {
      window.sessionStorage.removeItem('seenBanner');
    }
  }
  if (endOfTheEventDate.getTime() > date.getTime() && isBrowser && !window.sessionStorage.getItem('seenBanner')) {
    setTimeout(() => {
      openModal();
    }, 5000)

  }
  function openModal() {
    setModalIsOpen(true);
    window.sessionStorage.setItem('seenBanner', true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    if (headerDark) {
      gContext.goHeaderDark();
    } else {
      gContext.goHeaderLight();
    }

    if (footerDark) {
      gContext.goFooterDark();
    } else {
      gContext.goFooterLight();
    }
  }, [gContext, headerDark, footerDark]);

  return (
    <>
      {children}
      <CookieBanner />
      <Rodal
          visible={modalIsOpen}
          onClose={closeModal}
          animation={'door'}
          closeMaskOnClick={false}
          className={'banner-modal'}
      >
        <img
            src={wptGlobalPng}
            alt="First land of poker"
        />
        <div className="d-flex flex-column align-items-center mt-2">
          <a href={'/blog/wptBlog'} onClick={closeModal}>
            <Button mb={0} bg="#408597">
              Register now
            </Button>
          </a>
        </div>
      </Rodal>
    </>
  );
};

export default PageWrapper;

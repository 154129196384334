import React from "react";
import { withTheme } from "styled-components";
import CookieConsent, { Cookies } from "react-cookie-consent";

import FlopFormattedMessageWrapper from "../Flop/FlopFormattedMessageWrapper";

const CookieBanner = ({ theme }) => {
  return (
    Cookies.get("gdpr-analytics-enabled") === undefined && (
      <CookieConsent
        containerClasses="font-muli"
        cookieName="gdpr-analytics-enabled"
        style={{
          padding: "5px 56px 5px 56px",
          backgroundColor: theme.colors.darkGreyOpacity50,
        }}
        buttonStyle={{
          backgroundColor: theme.colors.primary,
          borderRadius: 5,
          color: "white",
          outline: "none",
          marginLeft: "12px",
        }}
        buttonText={
          <FlopFormattedMessageWrapper id="index.cookie.button-accept" />
        }
        buttonId="cookie-consent"
        debug
        declineButtonStyle={{
          backgroundColor: theme.colors.greyBg,
          borderRadius: 5,
          color: "black",
          outline: "none",
          marginLeft: "12px",
        }}
        declineButtonText={
          <FlopFormattedMessageWrapper id="index.cookie.button-decline" />
        }
        declineButtonId="cookie-decline"
        enableDeclineButton
        onAccept={() => {
          Cookies.set("gdpr-analytics-enabled", true);
        }}
      >
        <FlopFormattedMessageWrapper id="index.cookie.constment-phrase" />
      </CookieConsent>
    )
  );
};

export default withTheme(CookieBanner);
